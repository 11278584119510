import * as Sentry from '@sentry/react'
import { matchPath } from 'react-router-dom'

import { store } from '@/utilities/store'
import NewOrderSound from '@/assets/audio/new-order.mp3'
import { KdsDisplayOrderContract, KdsOrderStatus, KdsVenueContract, OrderOrigin, PrintStatus } from '@/types/api'
import { PrintData, print } from './print'
import { format } from 'date-fns'

const newOrderSound = new Audio(NewOrderSound)

export const getWindowSizes = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  }
}

export const playNewOrderSound = async () => {
  try {
    await newOrderSound.play()
  } catch (error) {
    console.error(error)
  }
}

export const logout = () => {
  store.dispatch.authentication.resetState()
  store.dispatch.locations.resetState()
  store.dispatch.orders.resetState()

  setSentryUser(null)
}

export const getFilteredOrders = (location?: KdsVenueContract, orders?: KdsDisplayOrderContract[]) => {
  if (!location || !orders) {
    return []
  }

  return orders.filter((order) => {
    switch (order.origin) {
      case OrderOrigin.Bolt:
        return !!location.showBoltOrders
      case OrderOrigin.Wolt:
        return !!location.showWoltOrders
      case OrderOrigin.Qr:
        return !!location.showQrOrders
      case OrderOrigin.SelfService:
        return !!location.showSelfServiceOrders
      default:
        return true
    }
  })
}

export const minutesToMs = (minutes: number) => {
  return 1000 * 60 * minutes
}

export const setSentryUser = (user: Sentry.User | null) => {
  Sentry.setUser(user)
}

const getPrinterImgCtx = async (src?: string, height: number = 128) => {
  if (!src) {
    return
  }

  const canvas = document.createElement('canvas')

  const ctx = canvas.getContext('2d')
  if (!ctx) {
    return
  }

  const img = new Image()
  img.src = src
  img.crossOrigin = 'anonymous'

  try {
    await new Promise<void>((resolve, reject) => {
      img.onload = () => {
        resolve()
      }

      img.onerror = reject
    })

    const width = Math.round(height * (img.naturalWidth / img.naturalHeight))

    canvas.width = width
    canvas.height = height

    img.width = width
    img.height = height

    ctx.drawImage(img, 0, 0, width, height)

    return ctx
  } catch (error) {
    console.error(error)
  } finally {
    img.remove()
    canvas.remove()
  }
}

const waitForMS = (ms: number) => {
  return new Promise<void>((resolve) => {
    const timeout = setTimeout(() => {
      resolve()

      clearTimeout(timeout)
    }, ms)
  })
}

const getOrdersToPrint = async (location: KdsVenueContract) => {
  const orders = await store.dispatch.orders.getKitchenOrders({ locationId: location.id!, onlyData: true })

  const createdOrders = orders.filter((order) => order.status === KdsOrderStatus.Created)

  return createdOrders.reduce<KdsDisplayOrderContract[]>((arr, order) => {
    if (order.printStatus === PrintStatus.Printed) {
      return arr
    }

    switch (order.origin) {
      case OrderOrigin.Bolt:
        if (location.printBoltOrders) {
          arr.push(order)
        }
        break
      case OrderOrigin.Qr:
        if (location.printQrOrders) {
          arr.push(order)
        }
        break
      case OrderOrigin.SelfService:
        if (location.printSelfServiceOrders) {
          arr.push(order)
        }
        break
      case OrderOrigin.Web:
        if (location.printWebOrders) {
          arr.push(order)
        }
        break
      case OrderOrigin.Wolt:
        if (location.printWoltOrders) {
          arr.push(order)
        }
        break
      // These orders must not be printed
      case OrderOrigin.Deliverect:
      case OrderOrigin.OrderProcessor:
        break
      default:
        break
    }

    return arr
  }, [])
}

const printOrder = async (
  printerIpAddress: string,
  order: KdsDisplayOrderContract,
  printOrderType: boolean,
  receiptLogoImageName?: string | null,
) => {
  const data: PrintData[] = []

  if (receiptLogoImageName) {
    const imgCtx = await getPrinterImgCtx(`${process.env.VITE_APP_IMGIX_URL}/${receiptLogoImageName}`)
    if (imgCtx) {
      data.push({
        type: 'image',
        ctx: imgCtx,
        newLines: 3,
      })
    }
  }

  let header = ''
  if (printOrderType && order.type) {
    header += order.type
  }
  if (order.table) {
    header += header ? `\t|\t${order.table}` : order.table
  }
  if (header) {
    data.push({
      type: 'text',
      textSize: 2,
      newLines: 3,
      text: header,
    })
  }

  if (order.number) {
    data.push({
      type: 'text',
      textSize: 3,
      newLines: order.commentToKitchen ? 1 : 3,
      text: `#${order.number}`,
    })
  }

  if (order.commentToKitchen) {
    data.push({
      type: 'text',
      textSize: 2,
      newLines: 3,
      text: order.commentToKitchen,
    })
  }

  let products = ''
  order.orderProducts?.forEach((orderProduct) => {
    products += `${orderProduct.count}x ${orderProduct.title}\n`

    orderProduct.options?.forEach((option) => {
      products += `\t${option.count}x ${option.title}\n`
    })
  })
  if (products) {
    data.push({
      type: 'text',
      textSize: 1,
      newLines: 2,
      text: products,
    })
  }

  if (order.guestIdentification) {
    data.push({
      type: 'text',
      textSize: 2,
      newLines: 3,
      text: `Name: ${order.guestIdentification}`,
    })
  }

  data.push({
    type: 'text',
    textSize: 1,
    newLines: 1,
    text: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    center: true,
  })

  if (order.name) {
    data.push({
      type: 'text',
      textSize: 1,
      newLines: 3,
      text: order.name,
      center: true,
    })
  }

  await print(printerIpAddress, data)
}

export const printOrders = async () => {
  try {
    store.dispatch.app.setPrinting(true)

    const locationId = matchPath({ path: '/:locationId', end: false }, window.location.pathname)?.params?.locationId
    if (!locationId) {
      throw new Error('No location id.')
    }

    const printerIpAddress = store.getState().app.printerIpAddress
    const locations = store.getState().locations.locations

    const location = locations.find((l) => l.id === locationId)
    if (!location) {
      throw new Error('No location.')
    }

    const ordersToPrint = await getOrdersToPrint(location)

    for (const order of ordersToPrint) {
      await printOrder(
        printerIpAddress,
        order,
        !!location.printOrderType,
        location.enableReceiptLogoPrinting ? location.receiptLogoImageName : null,
      )

      await store.dispatch.orders.markKitchenOrderPrinted(order.id!)

      await waitForMS(1000)
    }
  } catch (error) {
    store.dispatch.app.setPrinterHasError(true)

    Sentry.captureException(error)

    console.error(error)
  } finally {
    store.dispatch.app.setPrinting(false)
  }
}
